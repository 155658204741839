.main-container {
  display: flex;
  height: calc(100vh - 60px);
  padding-top: 60px;
  width: 100%;
  background-image: linear-gradient(#282c34, lightgrey);
  align-items: center;
  flex-direction: column;
  gap: 2rem;

  .instruction-text {
    margin-top: 1rem;
    display: flex;
    color: white;
  }
}
